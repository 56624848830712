const INITIAL_STATE = {
    notifications: [],
    adminNotificationList: [],
    loading: true
};

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION':
            return {
                ...state,
                notifications: action.notifications
            };
        case 'GET_ALL_ADMIN_NOTIFICATION':
            return {
                ...state,
                adminNotificationList: action.adminNotificationList
            };
        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
};

export default notificationReducer;
