const INITIAL_STATE = {
    userList: [],
    getUser: {},
    getAnswer: [],
    getUserView: [],
    loading: true

};


const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_USER_LİST':
            return {
                ...state,
                userList: action.userList
            };
        case 'GET_USER_BY_USERID':
            return {
                ...state,
                getUser: action.getUser
            };
        case 'GET_ANSWER_BY_USERID':
            return {
                ...state,
                getAnswer: action.getAnswer
            };
        case 'USER_VIEW_FAQ':
            return {
                ...state,
                getUserView: action.getUserView
            }
        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
};
export default userReducer;