const INITIAL_STATE = {
    reminderList: [],
    reminderDetail: {},
    loading: true
};

const reminderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_REMINDER':
            return {
                ...state,
                reminderList: action.reminderList
            };
        case 'GET_REMINDER_BYID':
            return {
                ...state,
                reminderDetail: action.reminderDetail
            };
        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
};
export default reminderReducer;