// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import user from './user'
import faq from './faq'
import form from './form'
import reminder from './reminder'
import notification from './notification'
import report from './report'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  user,
  faq,
  form,
  reminder,
  notification,
  report
})

export default rootReducer
