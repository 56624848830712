const INITIAL_STATE = {
    faqList: [],
    pageInfo: [],
    filteredFAQ: [],
    faqListByUserId: [],
    loading: true
};

const faqReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'GET_FAQ_LİST':
            return {
                ...state,
                faqList: action.faqData.list,
            };
        case 'GET_FAQ_FILTERED':
            return {
                ...state,
                filteredFAQ: action.filteredFAQ
            }
        case 'GET_USER_FAQ_LIST':
            return {
                ...state,
                faqListByUserId: action.faqListByUserId
            }
        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
};

export default faqReducer;