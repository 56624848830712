const INITIAL_STATE = {
    loading: true,
    faqListCount: [],
    userListByFaqId: [],
    formListQuestions: [],
    getQuestionAnswer: [],
    questionInfo: {}

}

const reportReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'GET_FAQ_LIST_COUNT':
            return {
                ...state,
                faqListCount: action.faqListCount
            };
        case 'GET_USER_LIST_BY_FAQ_ID':
            return {
                ...state,
                userListByFaqId: action.userListByFaqId
            }
        case 'GET_FORM_LIST_WITH_QUESTIONS':
            return {
                ...state,
                formListQuestions: action.formListQuestions
            }

        case 'GET_QUESTION_ANSWER_COUNT':
            return {
                ...state,
                getQuestionAnswer: action.getQuestionAnswer
            }

        case 'GET_QUESTION_INFO':
            return {
                ...state,
                questionInfo: action.questionInfo
            }

        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}

export default reportReducer