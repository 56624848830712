const INITIAL_STATE = {
    form: {},
    formList: [],
    loading: true,
    userFormList: [],
    userRequiredTotalFormList: []
};

const formReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_FORM':
            return {
                ...state,
                form: action.form
            };
        case 'GET_ALL_FORM':
            return {
                ...state,
                formList: action.formList
            }
        case 'GET_USER_FORM':
            return {
                ...state,
                userForm: action.userForm
            }
        case 'LOADING':
            return {
                ...state,
                loading: action.loading
            }
        case 'GET_USER_FORMLIST':
            return {
                ...state,
                userFormList: action.userFormList
            }
        default:
            return state;
    }
};

export default formReducer;